<template>
  <v-form ref="form" @submit.prevent="submit" pa-0 fluid class="state-admin-form">
    <v-layout row wrap class="state-admin-form__content">
      <v-flex xs12 md12 px-2>
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('affiliate', arguments[0])"
          :value="affiliate"
          :rules="['required']"
          label="Affiliate"
        />
      </v-flex>
      <v-flex xs12 md12 px-2>
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput('contact', arguments[0])"
          :value="contact"
          :rules="['required']"
          label="Contact"
        />
      </v-flex>
      <v-flex xs12 md12 px-2>
        <v-text-field
          autocomplete="new-password"
          type="email"
          @input="handleFieldInput('email', arguments[0])"
          :value="email"
          :rules="['required', 'email']"
          label="Email"
        />
      </v-flex>
      <p class="state-admin-form__submit">
        <v-btn @click="$emit('close')" outlined :loading="loading">
          Cancel
        </v-btn>
        <v-btn @click="submit" :loading="loading">
          Submit
        </v-btn>
      </p>
    </v-layout>
  </v-form>
</template>

<script>
import { syncForm } from '@/mixins';
import { genders, organizationRoles } from '@/utils/options';
import validateForm from '@/mixins/validateForm';

export default {
  name: 'StateAdminForm',
  mixins: [syncForm, validateForm],
  props: {
    loading: Boolean,
    affiliate: {
      type: String,
      default: () => '',
    },
    contact: {
      type: String,
      default: () => '',
    },
    email: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      selectOptions: {
        genders,
        organizationRoles,
      },
    };
  },
};
</script>

<style lang="scss">
.state-admin-form {
  &__content {
    max-width: 380px;
    margin: 0 auto;
    padding: 8px 0;
  }

  &__submit {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      margin: 0 10px;
    }
  }
}
</style>

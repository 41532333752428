<template>
  <PhDialog
    :value="showOrgCreate"
    fullscreen
    :shadowContentScroll="true"
    @input="closeOrgCreateModal"
    closeBtnText="ADD"
    hideFooter
  >
    <h1 class="orgcreate-header" slot="header">
      State Admins
    </h1>
    <div slot="content" class="org-create__content">
      <v-btn v-if="!addFormShown" @click="toggleAddForm" :loading="loading">
        Add State Admin
      </v-btn>

      <StateAdminForm
        v-if="addFormShown"
        v-bind.sync="addSAForm"
        @close="toggleAddForm"
        @submit="handleAddStateAdmin"
        :loading="loading"
      />
      <div class="org-create__table">
        <PhTable :headers="headers" :items="stateAdmins">
          <template v-slot:[`item.action`]="{ item }">
            <v-icon @click="handleRemoveStateAdmin(item.id)" size="20">delete</v-icon>
          </template>
        </PhTable>
      </div>
    </div>
  </PhDialog>
</template>

<script>
import PhDialog from '@/components/modals/PhDialog';
import StateAdminForm from '@/components/forms/StateAdminForm';
import PhTable from '@/components/tables/PhTable';

const HEADERS = [
  {
    text: 'Affiliate',
    value: 'affiliate',
    width: 150,
  },
  {
    text: 'Contact',
    value: 'contact',
    width: 150,
  },
  {
    text: 'Email',
    value: 'email',
    sortable: false,
    width: 150,
  },
  {
    text: '',
    value: 'action',
    sortable: false,
    width: 30,
  },
];

const SA_FORM = {
  affiliate: '',
  contact: '',
  email: '',
};

export default {
  name: 'OrgSA',
  props: {
    showOrgCreate: Boolean,
    loading: Boolean,
    stateAdmins: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PhDialog,
    StateAdminForm,
    PhTable,
  },
  data: () => ({
    addFormShown: false,
    addSAForm: { ...SA_FORM },
    headers: HEADERS,
  }),
  methods: {
    toggleAddForm(payload = !this.addFormShown) {
      this.addFormShown = payload;
    },
    closeOrgCreateModal() {
      this.$emit('closeOrgCreateDialog');
      this.resetForm();
    },
    handleAddStateAdmin() {
      this.$emit('addStateAdmin', { ...this.addSAForm });

      this.toggleAddForm(false);
      this.resetForm();
    },
    handleRemoveStateAdmin(id) {
      this.$emit('removeStateAdmin', id);
    },
    resetForm() {
      Object.assign(this.addSAForm, { ...SA_FORM });
    },
  },
};
</script>

<style lang="scss">
.org-create__form-title {
  text-align: left;
}
.org-create__content {
  height: 100%;
  overflow-y: auto;
}
.orgcreate-header {
  text-transform: capitalize;
}
</style>

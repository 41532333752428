<template>
  <v-container fill-height>
    <div class="organizations">
      <v-layout
        class="organizations__header-row organizations__header-row--top"
        align-center
        justify-space-between
      >
        <PageTitle>Sport Organizations</PageTitle>
        <div>
          <v-btn v-if="$userIs('SUPER_ADMIN') && computedOrgs.length" @click="handleShowOrgCreate">
            + ADD SPORT ORG
          </v-btn>
        </div>
      </v-layout>
      <v-layout align-center justify-space-between class="organizations__filter-bar">
        <PageFilterBar>
          <PageFilterGroup grow>
            <PageFilter type="search" wide>
              <SearchTextField v-model="orgSearchQuery" placeholder="Find an organization">
              </SearchTextField>
            </PageFilter>
          </PageFilterGroup>
        </PageFilterBar>
      </v-layout>
      <v-layout class="organizations__layout-wrapper">
        <transition name="fade" appear>
          <div v-if="loadingOrganizations" class="organizations__preloader-wrapper">
            <Preloader :value="loadingOrganizations" transparent></Preloader>
          </div>
        </transition>
        <v-layout v-if="computedOrgs.length" class="organizations__layout">
          <OrgCard
            v-for="org in computedOrgs"
            :key="org.id"
            :org="org"
            @showOrg="handleShowOrganization"
          ></OrgCard>
        </v-layout>
        <div v-else-if="organizationsLoaded" class="organizations__placeholder-wrapper">
          <ViewPlaceholder type="orgs">
            <v-btn v-if="$userIs('SUPER_ADMIN')" @click="handleShowOrgCreate">
              + ADD SPORT ORG
            </v-btn>
          </ViewPlaceholder>
        </div>
      </v-layout>
    </div>
    <template v-if="updateOrgDialog.type !== 'stateAdmin'">
      <OrgCreate
        :showOrgCreate="updateOrgDialog.show"
        :orgForm="orgForm"
        :type="'sport'"
        :showOrgType="updateOrgDialog.type"
        :loading="orgLoading"
        @flushForm="handleFlushForm"
        @orgFormLogoUpdate="handleUserOrgFileInput"
        @landingPageSettingsUpdate="handleLandingPageSettingsInput"
        @orgFormUpdate="handleUpdateOrgForm"
        @closeOrgCreateDialog="handleCloseOrgForm"
        @submit="handleSubmitOrgForm"
        @prepareContentToEdit="handlePrepareContentToEdit"
        @generateLink="handleGenerateLink"
        @flushLink="handleFlushLink"
      >
      </OrgCreate>
    </template>
    <template v-if="updateOrgDialog.type === 'stateAdmin'">
      <OrgSA
        @addStateAdmin="handleAddStateAdmin"
        @removeStateAdmin="handleRemoveStateAdmin"
        @closeOrgCreateDialog="handleCloseOrgForm"
        :stateAdmins="sidebarContent.stateAdmins"
        :showOrgCreate="updateOrgDialog.show"
        :org="orgForm"
        :type="'stateAdmin'"
        :loading="orgLoading"
      ></OrgSA>
    </template>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import { PageFilter, PageFilterBar, PageFilterGroup, SearchTextField } from '@/components/filters';
import { responseDialogs } from '@/mixins';
import PageTitle from '@/components/typography/PageTitle';
import Preloader from '@/components/Preloader';
import OrgCard from '@/components/OrgCard';
import OrgCreate from '@/components/modals/OrgCreate/index';
import OrgSA from '@/components/modals/OrgSA/index';
import ViewPlaceholder from '@/components/ViewPlaceholder';
import orgFormData from '@/mixins/orgFormData';

export default {
  name: 'Organizations',
  components: {
    OrgCard,
    OrgCreate,
    OrgSA,
    PageTitle,
    PageFilter,
    PageFilterBar,
    PageFilterGroup,
    Preloader,
    SearchTextField,
    ViewPlaceholder,
  },
  mixins: [orgFormData, responseDialogs],
  data() {
    return {
      showOrgCreate: false,
      orgLoading: false,
      orgSearchQuery: '',
    };
  },
  computed: {
    ...mapGetters('app', ['updateOrgDialog']),
    ...mapGetters('organizations', [
      'loadingOrganizations',
      'organizations',
      'organizationsLoaded',
    ]),
    computedOrgs() {
      if (this.organizations.length) {
        return this.organizations
          .filter(org => {
            const lowerName = org.name ? org.name.toLowerCase() : org.name;
            const searchQuery = this.orgSearchQuery
              ? this.orgSearchQuery.toLowerCase()
              : this.orgSearchQuery;
            const orgs = {
              orgName: searchQuery === '' || lowerName.includes(searchQuery),
            };
            return orgs.orgName;
          })
          .sort((x, y) => {
            return Date.parse(y.createdAt) - Date.parse(x.createdAt);
          });
      }
      return [];
    },
  },
  methods: {
    ...mapActions('organizations', [
      'fetchSportsUnions',
      'createSportsUnion',
      'updateSportsUnion',
      'addStateAdmin',
      'removeStateAdmin',
    ]),
    ...mapActions('brokerOrganizations', ['fetchBrokerOrganizations']),
    ...mapActions('app', ['openSidebarInfo', 'showUpdateOrgDialog', 'hideUpdateOrgDialog']),
    handleShowOrgCreate() {
      this.showUpdateOrgDialog({
        content: 'organization',
        type: 'create',
      });
    },
    handleShowOrganization(id) {
      this.openSidebarInfo({
        id,
        content: 'organization',
      });
    },
    handleCloseOrgForm() {
      this.hideUpdateOrgDialog();
    },
    handleAddStateAdmin(payload) {
      this.orgLoading = true;
      this.addStateAdmin({
        id: this.infoSidebarData.id,
        form: payload,
      }).finally(() => {
        this.orgLoading = false;
      });
    },
    handleRemoveStateAdmin(id) {
      this.orgLoading = true;

      this.removeStateAdmin({
        id: this.infoSidebarData.id,
        stateAdminId: id,
      }).finally(() => {
        this.orgLoading = false;
      });
    },
    handleSubmitOrgForm() {
      this.orgLoading = true;
      const clonedForm = JSON.parse(JSON.stringify(this.orgForm));
      clonedForm.landingPageSettings.social = Object.entries(
        clonedForm.landingPageSettings.social
      ).map(({ 0: icon, 1: src }) => ({
        icon,
        src,
      }));
      ['primaryColor', 'secondaryColor', 'stepsBgColor', 'bgColor', 'heroTextColor'].forEach(
        key => {
          clonedForm.landingPageSettings[key] =
            clonedForm.landingPageSettings[key].hex || clonedForm.landingPageSettings[key];
        }
      );
      ['logo', 'plainTextLogoBg', 'bgImage'].forEach(key => {
        if (
          this.orgForm.landingPageSettings &&
          this.orgForm.landingPageSettings[key] &&
          this.orgForm.landingPageSettings[key].id
        ) {
          clonedForm.landingPageSettings[`${key}Id`] = this.orgForm.landingPageSettings[key].id;
        } else {
          clonedForm.landingPageSettings[`${key}Id`] = '';
        }
        delete clonedForm.landingPageSettings[key];
      });
      if (this.updateOrgDialog.type === 'edit') {
        this.updateSportsUnion({
          id: this.infoSidebarData.id,
          form: clonedForm,
        }).then(() => {
          this.orgLoading = false;
          this.hideUpdateOrgDialog();
        });
      } else {
        this.createSportsUnion(clonedForm)
          .then(() => {
            this.orgLoading = false;
            this.hideUpdateOrgDialog();
            this.showSuccessResponseDialog(
              {
                content: '<h3>Huzzuh!</h3><div>You’ve just registered a Sports Organization</div>',
                icon: 'shield--green',
              },
              'Revise'
            );
          })
          .catch(error => {
            this.orgLoading = false;
            this.$dialog.error({ error });
          });
      }
    },
    handlePrepareContentToEdit() {
      this.handlePrepareOrgToEdit();
    },
  },
  mounted() {
    this.fetchBrokerOrganizations();
    this.fetchSportsUnions();
  },
};
</script>

<style lang="scss">
@import '~@/scss/views/organizations';
</style>
